import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import adminRouter from './router'
import companyRouter from './router/company'
import './assets/css/style.scss';
import store from './store';
import i18n from './locale';
import Api from "./services/";
import VueHtml2pdf from 'vue-html2pdf'
import drag from "v-drag"
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'


Vue.use(drag, {
  removeTransition: false // default: `true`
})

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi"
})
Vue.config.productionTip = false
Vue.use(Api, { store });

Vue.use(VueHtml2pdf);

// code splite for subdomain
const host = window.location.host;
const parts = host.split('.');
const domainLength = 4; // company1.bina.com => domain length = 3

const router = () => {
  let routes;
  if (parts.length === (domainLength - 1) || parts[0] === 'www') {
    routes = adminRouter;
  } else if (parts[0]) {
    routes = companyRouter;
  } else {
    // If you want to do something else just comment the line below
    routes = adminRouter;
  }
  return routes;
};

new Vue({
  router: router(),
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
