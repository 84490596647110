export default {
  generalEvaluation: "General Evaluation",
  customerObligations: "Customer Obligations",
  supplierObligations: "Supplier Obligations",
  totalObligations: "Total Obligations",
  incomeForecast: "Income Forecast",
  expensesForecast: "Expenses Forecast",
  totalDifference: "Total Difference",
  salesAndReceipts: "Sales and Receipts",
  salesAndPurchases: "Sales and Purchases",
  debts: "Debts",
  futureChecks: "Future Checks",
  totalCustomers: "Total Customers",
  totalSuppliers: "Total Suppliers",
  deliveryNotes: "Delivery Notes",
  agentDeliveryNotes: "Agent Delivery Notes",
  proformas: "Proformas",
  orderInProduction: "Orders In Production",
  totalForecast: "Total Forecast",
  supplierOrders: "Supplier Orders",
  supplierChecks: "Supplier Checks",
  month: "Month",
  invoices: "Invoices",
  accumulated: "Accumulated",
  receipts: "Receipts",
  purchases: "Purchases",
  today: "Today",
  customerOrders: "Customer orders",
  proformaInvoices: "Proforma invoices",
  inflow: "Inflow",
  outflow: "Outflow",
  pendingDeposits: "Pending Deposits",
  creditInvoices: "Credit Invoices",
  withoutVat: "Without VAT (w/o vat)",
  addProformas: "Add Proformas",
  chooseYear: "Choose Year",
  refresh: "Refresh",
  showPurchase: "Show Purchases",
  showReceipt: "Show Receipts",
  multiYear: "Multi Year",
  multiYearEvaluation: "Multi Year Evaluation",
  total: "Total",
  invoiceReport: "Invoices Report",
  receiptReport: "Receipts Report",
  purchaseReport: "Purchases Report",
  customerDebts: "Customer Debts",
  customerFutureChecks: "Customer Future Checks",
  supplierDebts: "Supplier Debts",
  remainigQtyAmount: "Remaining Quantity Amount",
  totalUnitPrice: "Total Unit Price",
  supplierReceiptDate: "Supplier Receipt Date",
  paymentTermsId: "Payment Terms Id",
  supplierPaymentDate: "Supplier Payment Date",
  monthList: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  }
};
