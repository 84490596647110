export default {
  generalEvaluation: "שערוך כללי",
  customerObligations: "אובליגו לקוחות",
  supplierObligations: "אובליגו ספקים",
  totalObligations: 'סה"כ אובליגו',
  incomeForecast: "צפי הכנסות",
  expensesForecast: "צפי הוצאות",
  totalDifference: 'סה"כ הפרש',
  salesAndReceipts: "מכירות ותקבולים",
  salesAndPurchases: "מכירות ורכישות",
  debts: "חייבים",
  futureChecks: "ממסרים",
  totalCustomers: 'סה"כ לקוחות',
  totalSuppliers: 'סה"כ ספקים',
  deliveryNotes: "ת. משלוח פתוחות",
  agentDeliveryNotes: "ת.מ. סוכן פתוחות",
  proformas: "חשבונות עסקה פתוחים",
  orderInProduction: "הזמנות פתוחות",
  totalForecast: 'סה"כ צפי',
  supplierOrders: "הזמנות רכש",
  supplierChecks: "צ'קים לספקים",
  month: "ת. משלוח פתוחות",
  invoices: "ת.מ. סוכן פתוחות",
  accumulated: "מצטבר",
  receipts: "קבלות",
  purchases: "רכישות",
  today: "היום",
  customerOrders: "הזמנות",
  proformaInvoices: "חשבונות עסקה",
  inflow: "שקים נכנסים",
  outflow: "שקים יוצאים",
  pendingDeposits: "שקים להפקיד",
  creditInvoices: "חשבונית זיכוי",
  withoutVat: 'ללא מע"מ',
  chooseYear: "בחר שנה",
  addProformas: "הוסף חשבונות",
  refresh: "רענן",
  showPurchase: "הצג רכישות",
  showReceipt: "הצג תקבולים",
  multiYear: "רב שנתי",
  multiYearEvaluation: "הערכה רב שנתית",
  total: 'סה"כ',
  invoiceReport: "דוח חשבונית",
  receiptReport: "דוח קבלה",
  purchaseReport: "דו”ח רכישות",
  customerDebts: "חובות לקוחות",
  customerFutureChecks: "בדיקות עתידיות של לקוחות",
  supplierDebts: "חובות ספקים",
  remainigQtyAmount: "כמות הנותרת",
  totalUnitPrice: "מחיר יחידה כולל",
  supplierReceiptDate: "תאריך קבלה של הספק",
  paymentTermsId: "מזהה תנאי תשלום",
  supplierPaymentDate: "תאריך תשלום הספק",
  monthList: {
    january: "ינואר",
    february: "פברואר",
    march: "מרץ",
    april: "אפריל",
    may: "מאי",
    june: "יוני",
    july: "יולי",
    august: "אוגוסט",
    september: "ספטמבר",
    october: "אוקטובר",
    november: "נובמבר",
    december: "דצמבר",
  },
};
